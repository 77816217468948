function getFaviconEl(window) {
    return window.document.getElementById("favicon");
}


function isDarkMode() {
    if (window.matchMedia) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}

export function changeThemeHandler(window) {
    const isDark = isDarkMode(window);
    const favicon = getFaviconEl(window);

    if (isDark) {
        favicon.href = window.location.origin + '/logo_dark.png';
        return;
    }

    favicon.href = window.location.origin + '/logo_light.png';
}