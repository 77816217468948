import './App.css';

import React, { useState, useEffect } from "react";

import LogoIcon from '../../assets/components/logo_icon';
import Carousel from '../carousel/carousel';

import { colors } from '../../utils/styles'
import { background_view_types } from '../../utils/constants'
import { changeThemeHandler } from '../../utils/utils'

function App() {

  const [background_view, setBackgroundView] = useState(background_view_types.CLOSE_1X)

  //control the device theme
  useEffect(() => {

    var colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    changeThemeHandler(window);
    colorSchemeQuery.addEventListener('change', () => changeThemeHandler(window));

    return () => {
      colorSchemeQuery.removeEventListener('change', changeThemeHandler);
    }

  }, [])

  /* return the correct background view */
  function BackgroundView() {

    /* CLOSED 1 TIME */
    if (background_view == background_view_types.CLOSE_1X) {
      return <LogoIcon
        height={'100%'}
        color={colors.blury_yellow}
        className={'background_logo_scaled_1X'}
      />
    }

    /* CLOSED 2 TIME */
    if (background_view == background_view_types.CLOSE_2X) {
      return <LogoIcon
        height={'170%'}
        color={colors.blury_yellow}
        className={'background_logo_scaled_2X'}
      />
    }

    /* CLOSED 3 TIME */
    if (background_view == background_view_types.CLOSE_3X) {
      return <LogoIcon
        height={'700%'}
        color={colors.blury_yellow}
        className={'background_logo_scaled_3X'}
      />
    }

    /* ALL BLACK */
    if (background_view == background_view_types.BLACK) {
      return <div></div>
    }

    return <div></div>
  }

  /* MAIN RENDER */

  return (
    <div className="app_container">
      <div className="background">
        <BackgroundView />
      </div>
      <div className="content_container">
        <Carousel setBackgroundView={setBackgroundView} />
      </div>
    </div>
  );
}

export default App;
