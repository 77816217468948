import React from "react";

function LogoIcon({ height, width, Theme, color, style, className }) {

  // const { height, width, Theme, color, style } = props;

  const HEIGHT = 211;
  const WIDTH = 243;

  return (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      x="0"
      y="0"
      enableBackground={`new 0 0 ${WIDTH} ${HEIGHT}`}
      version="1.1"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      xmlSpace="preserve"
      fill={color}
      className={className}
      style={style}
    >
      <g>
        <g>
          <g data-name="Grupo 1682">
            <path
              d="M182.264 210.612H60.768L0 105.323 60.768 0h121.5l.722 1.256 60.045 104.029-60.77 105.327zm-59.74-188.087A84.069 84.069 0 00105.9 24.2a81.873 81.873 0 00-29.492 12.439 82.673 82.673 0 1046.117-14.114"
            ></path>
          </g>
        </g>
      </g>
    </svg>

  );
}

export default LogoIcon;
