// import firebase from "firebase/app";
// import "firebase/firestore";
// import "firebase/auth";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAy4EbGCiUP8_rtgfZVrYPSBoB13KLsX7M",
  authDomain: "betty-better.firebaseapp.com",
  projectId: "betty-better",
  storageBucket: "betty-better.appspot.com",
  messagingSenderId: "494682193870",
  appId: "1:494682193870:web:1e8e6528a92fe7f9ea759f"
};


const app = firebase.initializeApp(firebaseConfig);
firebase.database = firebase.firestore(app);

export default firebase;
