

import firebase from "../../firebase/config";

//set user theme
export async function createLandingPageSubscribers({ email, name }) {
    return await firebase.database.collection("landing-page-subscribers").doc(email).set(
        {
            email,
            name,
            created: new Date()
        },
        { merge: true }
    );
}


//get BETTY CONFIGURATIONS CONSTANTS
export async function checkIfUserExist(email) {
    const snapshot = await firebase.database.collection("landing-page-subscribers").doc(email).get();

    return snapshot.exists;
}

export default {
    createLandingPageSubscribers,
    checkIfUserExist
}