export const colors = {
    blue: "#2649d0",
    green: "#25b098",
    red: "rgb(255 96 96)",
    soft_brown: "rgba(165, 42, 42,0.9)",
    dark_red: "#be0000",
    black: "#121212",
    soft_black: "#2d2d2d",
    soft_black2: "#3c3c3c",
    white: "#ffffff",
    orange: "#fa8a02",
    gray: "rgb(191 187 187)",
    dark_gray: "#808080",
    oddacy_dark_orange: "#f06564",
    oddacy_light_orange: "#f7996d",
    lightgray: "lightgray",
    yellow: "#FFD523",
    blury_yellow : '#ccc721',

    dark_yellow: "#ffdd00",
    soft_yellow: "#fffd7a",
  
    dark_green: "#25b098",
    soft_green: "#71eb71",
    soft_green2: "rgb(55 225 196)"
  };
  