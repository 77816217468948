export const carousel_views_types = {
    ABOUT_TO_HACK_TIME: 'ABOUT_TO_HACK_TIME',
    IF_YOU_PROCEED: 'IF_YOU_PROCEED',
    YOU_MAY_NEVER_LOOSE: 'YOU_MAY_NEVER_LOOSE',
    WELL_I_WISH_I_COULD: 'WELL_I_WISH_I_COULD',
    OK_YOU_WILL_BE_SUPRISED: 'OK_YOU_WILL_BE_SUPRISED',
    SELF_AWARENESS_IS_IMPORTANT: 'SELF_AWARENESS_IS_IMPORTANT',
    EMAIL_REGISTER: 'EMAIL_REGISTER',
    WELCOME: 'WELCOME'
};

export const background_view_types = {
    BLACK: 'BLACK',
    CLOSE_1X: 'CLOSE_1X',
    CLOSE_2X: 'CLOSE_2X',
    CLOSE_3X: 'CLOSE_3X',
};
