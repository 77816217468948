import React from "react";

function Logo({ height, width, Theme, color, style, className }) {

  // const { height, width, Theme, color, style } = props;

  const HEIGHT = 250.149;
  const WIDTH = 958.15;

  return (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      x="0"
      y="0"
      enableBackground={`new 0 0 ${WIDTH} ${HEIGHT}`}
      version="1.1"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      xmlSpace="preserve"
      fill={color}
      className={className}
      style={style}
    >

      <g id="Grupo_1682" data-name="Grupo 1682" transform="translate(-10042 67.43)">
        <path id="f"
          d="M182.264,210.612H60.768L0,105.323,60.768,0h121.5l.722,1.256,60.045,104.029ZM122.524,22.525A84.069,84.069,0,0,0,105.9,24.2,81.873,81.873,0,0,0,76.408,36.639a82.673,82.673,0,1,0,46.117-14.114"
          transform="translate(10042 -67.43)" />
        <path id="g"
          d="M984.443,79.877c-23.669,0-38.771,15.107-43.188,27.587h-.267V32.01H910.28V217.771h30.975V194.6h.268c4.679,11.718,19.252,26.52,42.654,26.52,36.682,0,62.707-28.348,62.707-71.041s-25.759-70.2-62.44-70.2M938.9,150.118c0-23.669,13.014-46.043,39.038-46.043s38.772,20.014,38.772,46.043-13.279,46.839-38.772,46.839S938.9,174.583,938.9,150.118m253.951,27.853H1162.94c-4.678,13.279-17.7,19.253-33.026,19.253-21.347,0-36.414-14.574-38.771-38.243h103.534c.189-1.9.267-3.806.267-5.707-.267-38.277-19.025-73.665-65.067-73.665-39.043,0-67.653,29.642-67.653,70.508s27.32,71.269,67.653,71.269c32,0,55.173-16.135,62.974-43.416m-101.483-40.333c2.855-20.814,17.163-34.588,38.51-34.588s33.559,15.335,35.387,34.588Zm165.751,33.826v-64.8h26.293v-23.4h-26.293V39.278l-29.642,13.279V83.265H1206.4v23.4h21.08v68.414c0,24.963,10.161,43.455,41.093,43.188l14.574-.267V193.8h-8.866c-13.013.039-17.163-7.762-17.163-22.336m86.6,0v-64.8h26.292v-23.4h-26.292V39.278l-29.643,13.279V83.265H1293v23.4h21.08v68.414c0,24.963,10.162,43.455,41.095,43.188l14.574-.267V193.8h-8.83c-13.012.039-17.2-7.762-17.2-22.336m70.774-88.2h-32c17.158,44.212,34.359,86.905,53.84,137.631l-4.149,10.656c-3.121,9.362-6.506,14.307-17.429,14.307H1399.91v23.935h23.136c18.226,0,28.349-9.362,34.359-25.492,34.36-94.443,26.521-69.708,59.322-161.03h-31.734c-11.452,35.921-23.136,70.775-33.027,103.307h-.533c-12.479-35.426-24.465-67.919-36.948-103.307"
          transform="translate(9483.423 -87.072)" />
      </g>
    </svg>

  );
}

export default Logo;
