import './carousel.css';


import React, { useState, useEffect } from "react";

import { colors } from '../../utils/styles'
import { carousel_views_types, background_view_types } from '../../utils/constants'

import Logo from '../../assets/components/logo'
import LogoIcon from '../../assets/components/logo_icon'
import helpers from './helpers'


function Carousel({ setBackgroundView }) {

  const [carousel_view, setCarouselView] = useState(carousel_views_types.ABOUT_TO_HACK_TIME)
  const [register_loading, setRegisterLoading] = useState(false)

  //we listen to then the carousel moves, we set the correct background
  useEffect(() => {
    setRegisterLoading(false)
    const layout_view = operations[carousel_view];
    setBackgroundView(layout_view.background)
  }, [carousel_view])

  //definition of moves to do
  const operations = {
    ABOUT_TO_HACK_TIME: {
      text: `Betty is pausing for now!\n\nWe're changing hands, but we hope you\nenjoyed the ride.\n Stay tuned—exciting new chapters are\ncoming soon!`,
      has_buttons: false,
      yes_click: () => setCarouselView(carousel_views_types.IF_YOU_PROCEED),
      no_click: () => setCarouselView(carousel_views_types.WELL_I_WISH_I_COULD),
      background: background_view_types.CLOSE_1X
    },
    IF_YOU_PROCEED: {
      text: `If you proceed you will unlock this\nseason's game results, are you sure?`,
      has_buttons: true,
      yes_click: () => setCarouselView(carousel_views_types.YOU_MAY_NEVER_LOOSE),
      no_click: () => setCarouselView(carousel_views_types.OK_YOU_WILL_BE_SUPRISED),
      background: background_view_types.CLOSE_2X
    },
    YOU_MAY_NEVER_LOOSE: {
      text: `You may never lose a bet again.\nIt might spoil you. Are you 100% sure?`,
      has_buttons: true,
      yes_click: () => setCarouselView(carousel_views_types.EMAIL_REGISTER),
      no_click: () => setCarouselView(carousel_views_types.SELF_AWARENESS_IS_IMPORTANT),
      background: background_view_types.CLOSE_2X
    },
    WELL_I_WISH_I_COULD: {
      text: `Well I wish I could say\nI didn't see this coming.`,
      has_buttons: true,
      single_button: true,
      yes_click: () => setCarouselView(carousel_views_types.ABOUT_TO_HACK_TIME),
      background: background_view_types.BLACK
    },
    OK_YOU_WILL_BE_SUPRISED: {
      text: `Ok. You will be suprised though.`,
      has_buttons: true,
      single_button: true,
      yes_click: () => setCarouselView(carousel_views_types.IF_YOU_PROCEED),
      background: background_view_types.BLACK,
    },
    SELF_AWARENESS_IS_IMPORTANT: {
      text: `Good.\nSelf-awareness is important.\nYou need to be mentally ready for it.`,
      has_buttons: true,
      single_button: true,
      yes_click: () => setCarouselView(carousel_views_types.YOU_MAY_NEVER_LOOSE),
      background: background_view_types.BLACK
    },
    EMAIL_REGISTER: {
      background: background_view_types.CLOSE_2X,

    },
    WELCOME: {
      text: `Welcome!`,
      subtitle: `I predict a bright future for you.\nMake sure to check your email. We will be in touch.`,
      has_buttons: false,
      yes_click: () => setCarouselView(carousel_views_types.IF_YOU_PROCEED),
      background: background_view_types.CLOSE_3X
    },
  }

  //the buttons option component
  function OptionButtons({ style, className, yes_click, no_click, single_button }) {

    //if its a single button to display
    if (single_button) {
      return (
        <div
          className={`option_buttons_container`}
        >
          <div onClick={yes_click} className='button_container single_button_container'>
            Let's give it another go then
          </div>
        </div>
      )
    }

    //double option button
    return (
      <div
        className={`option_buttons_container ${className}`}
      >
        <div onClick={yes_click} className='button_container'>
          Yes
        </div>
        <div onClick={no_click} className='button_container button_container_right'>
          No
        </div>
      </div>
    )
  }



  //on register user
  async function registUserHandler() {
    setRegisterLoading(!register_loading)

    const email = document.getElementById('email').value;
    const name = document.getElementById('name').value;

    console.log(email, name)
    if (!email || !name) return;

    const exist = await helpers.checkIfUserExist(email);

    if (exist) return;

    const created = await helpers.createLandingPageSubscribers({ email, name });

    console.log(created)
    //setCarouselView(carousel_views_types.WELCOME)
  }

  //map the correct inside carousel layout content
  function CarouselContent() {

    //we search for the definition of the current carosel view
    const layout_view = operations[carousel_view];

    //about to hack time has a special title
    if (carousel_view == carousel_views_types.ABOUT_TO_HACK_TIME) {
      return (
        <>
          <div className='title_big_text'>
            {layout_view.text}
          </div>
          {layout_view.has_buttons &&
            < OptionButtons className={'option_buttons_container_margin_big'} no_click={layout_view.no_click} yes_click={layout_view.yes_click} />
          }
        </>
      )
    }

    //about to hack time has a special title
    if (carousel_view == carousel_views_types.WELCOME) {
      return (
        <>
          <div className='title_big_text'>
            {layout_view.text}
          </div>
          <div className='carousel_subtitle_text'>
            {layout_view.subtitle}
          </div>
        </>
      )
    }

    //if its a component with only one button
    if (layout_view.single_button) {
      return (
        <>
          <div className='carousel_message_text'>
            {layout_view.text}
          </div>
          < OptionButtons
            single_button
            yes_click={layout_view.yes_click}
          />
        </>
      )
    }

    //the resgiter component
    if (carousel_view == carousel_views_types.EMAIL_REGISTER) {
      return (
        <div className='login_inputs_container'>
          <input
            id='name'
            placeholder='Name'
            className='input_container'
          // onChange={(e) => setName(e.target.value)}
          // value={name}
          />
          <input
            id='email'
            placeholder='E-mail'
            className='input_container'
          // onChange={(e) => setEmail(e.target.value)}
          // value={email}
          />
          <div onClick={registUserHandler} className='register_button'>
            {register_loading ? <LogoIcon className={'logo_spin'} height={17} /> : 'Send'}
          </div>
        </div>
      )
    }

    //all the defaults one
    return (
      <>
        <div className='carousel_message_text'>
          {layout_view.text}
        </div>
        {layout_view.has_buttons &&
          < OptionButtons no_click={layout_view.no_click} yes_click={layout_view.yes_click} />
        }
      </>
    )

  }

  return (
    <div className='carousel'>
      <div onClick={() => setCarouselView(carousel_views_types.ABOUT_TO_HACK_TIME)} className={'logo_container'}>
        <Logo
          height={'16px'}
          color={colors.white}
        />
      </div>

      <div className='carousel_content'>
        <CarouselContent />
      </div>

      <div className='rights_reserved_text'>2024 betty&reg; - All rights reserved . </div>
    </div>
  );
}

export default Carousel;
